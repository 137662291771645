// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

/** Ionic CSS Variables **/
:root {
  --ion-color-primary: #ff0065;
  --ion-color-primary-rgb: 255,0,101;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255,255,255;
  --ion-color-primary-shade: #e00059;
  --ion-color-primary-tint: #ff1a74;

  --ion-color-secondary: #19CDB2;
  --ion-color-secondary-rgb: 25,205,178;
  --ion-color-secondary-contrast: #000000;
  --ion-color-secondary-contrast-rgb: 0,0,0;
  --ion-color-secondary-shade: #16b49d;
  --ion-color-secondary-tint: #30d2ba;

  --ion-color-tertiary: #8481F5;
  --ion-color-tertiary-rgb: 132,129,245;
  --ion-color-tertiary-contrast: #000000;
  --ion-color-tertiary-contrast-rgb: 0,0,0;
  --ion-color-tertiary-shade: #7472d8;
  --ion-color-tertiary-tint: #908ef6;

  --ion-color-success: #66CC65;
  --ion-color-success-rgb: 102,204,101;
  --ion-color-success-contrast: #000000;
  --ion-color-success-contrast-rgb: 0,0,0;
  --ion-color-success-shade: #5ab459;
  --ion-color-success-tint: #75d174;

  --ion-color-warning: #FFB239;
  --ion-color-warning-rgb: 255,178,57;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0,0,0;
  --ion-color-warning-shade: #e09d32;
  --ion-color-warning-tint: #ffba4d;

  --ion-color-danger: #eb445a;
  --ion-color-danger-rgb: 235,68,90;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255,255,255;
  --ion-color-danger-shade: #cf3c4f;
  --ion-color-danger-tint: #ed576b;

  --ion-color-dark: #3f3f3f;
  --ion-color-dark-rgb: 93,93,93;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255,255,255;
  --ion-color-dark-shade: #525252;
  --ion-color-dark-tint: #6d6d6d;

  --ion-color-medium: #67A8FC;
  --ion-color-medium-rgb: 103,168,252;
  --ion-color-medium-contrast: #000000;
  --ion-color-medium-contrast-rgb: 0,0,0;
  --ion-color-medium-shade: #5b94de;
  --ion-color-medium-tint: #76b1fc;

  --ion-color-light: #999999;
  --ion-color-light-rgb: 153,153,153;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0,0,0;
  --ion-color-light-shade: #878787;
  --ion-color-light-tint: #a3a3a3;

  .toast-config{
    font-size: 1rem;
    text-align: center;
    --background: linear-gradient(to right,#3D70FF, #92C5FF);
  }

  ion-card{
    margin: 0.5rem auto;
    width: 100%;
  }

  ion-popover{
    ion-backdrop{
    opacity: 0.5 !important;
    }
    .popover-wrapper{
      .popover-content{
        width:80vw;
        max-width:500px;
      }
    }
  }
      
}